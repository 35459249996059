// components/Layout.js
import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, Button, SelectField, FieldGroupIconButton, SearchField } from '@aws-amplify/ui-react';
import { Header } from 'semantic-ui-react';
import { MdSearch } from 'react-icons/md';
import DomainAlert from './DomainWarning';
import './css/Layout.css';

const Layout = ({company, companyList, setCompany, setCompanyList}) => {
    const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);
    const navigate = useNavigate();

    function logOut() {
        signOut();
        navigate('/login');
    }

    const handleDropdownChange = (event) => {
        setCompany(event.target.value);
    };

    const [searchError, setSearchError] = useState(false);
    const [campaignId, setCampaignId] = useState('');

     const handleSearchChange = (e) => {
         if (e.target.value !== "") {
             const containsDigits = /^\d+$/.test(e.target.value);
             setSearchError(!containsDigits);
         } else {
             setSearchError(false);
         }
         setCampaignId(e.target.value);
     };
    
    // const handleSearchChange = (event) => {
    //     setCampaignId(event.target.value);
    // };

    const handleSearch = () => {
        if (!campaignId.trim()) {
            setSearchError(true)
            return;
        } else if (searchError) {
            return;
        }
        navigate(`/campaign/${campaignId}`);
        setCampaignId("");
    };

    const onClear = () => {
        setCampaignId('');
    };


  return (
<>
      <DomainAlert />
      <nav style={{ display: 'flex', justifyContent: 'space-between', padding: '1em', backgroundColor: '#1b1c1d' }}>
        <Button
          style={{ backgroundColor: 'transparent', color: '#f46f22', border: 'none', cursor: 'pointer', fontSize: '1.5em' }}
          onClick={() => navigate('/')}
        >
          🏠
        </Button> 
        {route === 'authenticated' &&
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Header as="h2" className="status-message"
                            style={{
                        color: '#b9b5b2',
                        fontSize: '1.5em',
                        fontWeight: 'normal',
                        marginRight: '10px',
                        marginBottom: '-10px',
                      }}
                >
                    Welcome, now viewing 
                </Header>
                <SelectField onChange={handleDropdownChange} value={company} options={companyList} size="large" style={{ color: '#b9b5b2' }} variation="quiet">
                </SelectField>
            </div>
        }
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {route === 'authenticated' && 
              <SearchField
                label="Search for Campaign by ID"
                labelHidden={true}
                placeholder="Search for Campaign by ID"
                value={campaignId}
                onChange={handleSearchChange}
                hasError={searchError}
                errorMessage="Please enter a valid Campaign ID"
                onSubmit={handleSearch}
                onClear={onClear}
              />
              // <TextField
              //   label="Search for Campaign by ID"
              //   labelHidden={true}
              //   placeholder="Search for Campaign ID..."
              //   value={campaignId}
              //   onChange={handleSearchChange}
              //   hasError={searchError}
              //   errorMessage="Please enter a valid Campaign ID"
              //   innerEndComponent={
              //       <FieldGroupIconButton
              //           ariaLabel="Search"
              //           variation="link"
              //           onClick={handleSearch}
              //       >
              //           <MdSearch />
              //       </FieldGroupIconButton>
              //   }
              // />
          }
          <Button
            // style={{ backgroundColor: '#f46f22', color: '#1b1c1d', border: 'none', cursor: 'pointer', marginRight: '1em' }}
            variation="primary"
            colorTheme="warning"
            onClick={() => navigate('/dashboard')}
            style={{ marginRight: '1em', marginLeft: '5em' }}
          >
            📊 Dashboard
          </Button>
          <Button
            // style={{ backgroundColor: '#f46f22', color: '#1b1c1d', border: 'none', cursor: 'pointer', marginRight: '1em' }}
            variation="primary"
            colorTheme="warning"
            onClick={() => navigate('/organic')}
            style={{ marginRight: '1em', marginLeft: '5em' }}
          >
            🌱 All Sales
          </Button>
          <Button
            // style={{ backgroundColor: '#f46f22', color: '#1b1c1d', border: 'none', cursor: 'pointer', marginRight: '1em' }}
            variation="primary"
            colorTheme="warning"
            onClick={() => navigate('/action_library')}
            style={{ marginRight: '1em' }}
          >
            📝 Action Items
          </Button>
          {route !== 'authenticated' ? (
            <Button
              // style={{ backgroundColor: '#f46f22', color: '#1b1c1d', border: 'none', cursor: 'pointer' }}
              variation="primary"
              colorTheme="warning"
              onClick={() => navigate('/login')}
              style={{ marginRight: '1em' }}
            >
              👤 Login
            </Button>
          ) : (
            <Button
              // style={{ backgroundColor: '#f46f22', color: '#1b1c1d', border: 'none', cursor: 'pointer' }}
              variation="primary"
              colorTheme="warning"
              onClick={() => logOut()}
              style={{ marginRight: '1em' }}
            >
              👤 Logout
            </Button>
          )}
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export { Layout };
